import { Environment } from '@abp/ng.core';

const baseUrl = 'https://app.americanalliedhealth.com';

const oAuthConfig = {
	issuer: 'https://auth.americanalliedhealth.com/',
	redirectUri: baseUrl,
	clientId: 'AAH_App',
	responseType: 'code',
	scope: 'offline_access AAH',
	requireHttps: true,
};

export const environment = {
	production: true,
	sentryEnvName: 'Production',
	application: {
		baseUrl,
		name: 'AAH',
	},
	oAuthConfig,
	simticsUrl: 'https://lti.simtics.com/api/v1/new',
	apis: {
		default: {
			url: 'https://api.americanalliedhealth.com',
			rootNamespace: 'AAH',
		},
		AbpAccountPublic: {
			url: oAuthConfig.issuer,
			rootNamespace: 'AbpAccountPublic',
		},
		Web: {
			url: 'https://americanalliedhealth.com',
			rootNamespace: 'AAH'
		}
	},
	remoteEnv: {
		url: '/getEnvConfig',
		mergeStrategy: 'deepmerge',
	},
} as Environment;
